/* Geo Apify Warning
 *
 * Any edits in this file should also be reflected in Geo Apify style file.
 * They are quite similar for significant portion of code.
*/

.input {
    vertical-align: top;
  width: 100%;
  height: 44px;
  padding: 9px 12px;
  background-color: #ffffff; /* reset grey background in some cases, such as <select> */
  background-clip: padding-box; /* hack for iOS to make box-shadow works (same as border-radius in the past) */
  border: 1px solid var(--input-border);
  outline: none;
  border-radius: var(--input-radius);
  font-family: inherit;
  font-size: 16px; /* not less than 16px (otherwise mobile browsers will zoom in on focus) */
  line-height: 24px;
  color: #000000;
  opacity: 1; /* reset select:disabled default fade on iOS */
  -webkit-tap-highlight-color: transparent;
  transition:
    background-color var(--transition-hover),
    border-color var(--transition-hover),
    box-shadow var(--transition-hover);
}

.input::placeholder {
  opacity: 1; /* reset opacity drop Firefox */
  color: var(--placeholder-color);
}

.input:hover {
    border-color: var(--input-border-hover);
    box-shadow: 0 0 1px var(--input-border-hover);
}

.input:focus-visible {
    border-color: var(--input-border-hover);
    box-shadow: 0 0 4px var(--input-border-hover);
    transition: none;
}


.input[type="password"]:not(:placeholder-shown) {
    font-family: "Tahoma", sans-serif;
    letter-spacing: 2px;
}


.input[type="color"] {
    width: 100px;
    padding: 4px 8px;
}


/* Either "appearance: none; width: 100%;" or "width: auto; to reset the width set above" */
.input[type="date"],
.input[type="datetime-local"],
.input[type="week"],
.input[type="month"],
.input[type="time"] {
    width: auto;
}


/* Disabled */
.input:disabled {
    border-color: var(--input-border);
    background-color: var(--disabled-background);
    color: var(--disabled-color);
}

.input:disabled::placeholder {
    color: var(--disabled-color);
}


/* Error */
.input--error {

}

.input--error {
    color: var(--danger);
    border-color: var(--danger);
}

.input--error:hover {
    border-color: var(--input-border-hover);
}

.input--error::placeholder {
    color: var(--danger);
}


textarea.input {
    height: auto; /* user rows="" attribute to set a default height */
    min-height: 44px; /* 1 row */
    max-height: 260px; /* 10 rows */
    resize: vertical;
}


select.input {
    appearance: none;
    padding-right: 50px;
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGZpbGw9IiMwMDAiIGQ9Ik0zLjI5MyA1LjYwNGExIDEgMCAwIDEgMS40MTQgMEw4IDguODk3bDMuMjkzLTMuMjkzYTEgMSAwIDEgMSAxLjQxNCAxLjQxNWwtNCA0YTEgMSAwIDAgMS0xLjQxNCAwbC00LTRhMSAxIDAgMCAxIDAtMS40MTV6Ii8+PC9zdmc+);
    background-size: 16px 16px;
    background-repeat: no-repeat;
    background-position: right 16px center;
    cursor: pointer;
}

select.input:hover {
    box-shadow: none; /* same as base, not hover input */
    border-color: var(--input-border); /* same as base, not hover input */
    background-color: #F6F6F6;
}

/* Disabled */
select.input:disabled {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGZpbGw9IiM1RDYxNjQiIGQ9Ik0zLjI5MyA1LjYwNGExIDEgMCAwIDEgMS40MTQgMEw4IDguODk3bDMuMjkzLTMuMjkzYTEgMSAwIDEgMSAxLjQxNCAxLjQxNWwtNCA0YTEgMSAwIDAgMS0xLjQxNCAwbC00LTRhMSAxIDAgMCAxIDAtMS40MTV6Ii8+PC9zdmc+);
    cursor: default;
}


/* Error */
select.input--error {
    background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGZpbGw9IiNGMDMiIGQ9Ik0zLjI5MyA1LjYwNGExIDEgMCAwIDEgMS40MTQgMEw4IDguODk3bDMuMjkzLTMuMjkzYTEgMSAwIDEgMSAxLjQxNCAxLjQxNWwtNCA0YTEgMSAwIDAgMS0xLjQxNCAwbC00LTRhMSAxIDAgMCAxIDAtMS40MTV6Ii8+PC9zdmc+);
}

select.input--error:hover {
    border-color: var(--danger);
}

select.input--placeholder-is-chosen {
    color: var(--placeholder-color);
}

select.input--placeholder-is-chosen:disabled {
    color: var(--disabled-color);
}

select.input--placeholder-is-chosen.input--error {
    color: var(--danger);
}

select.input--placeholder-is-chosen option {
    color: #000000; /* Browsers on Windows inherit grey color from <select> to <option>`s. Reset it. */
}


/* Geo Apify Warning
 *
 * Any edits in this file should also be reflected in Geo Apify style file.
 * They are quite similar for significant portion of code.
*/

@media (900px <= width < 1500px) {

    .input {
        height: 40px;
        padding: 7px 10px;
        font-size: 16px; /* not less than 16px (otherwise mobile browsers will zoom in on focus) */
        line-height: 24px;
    }

    .input:hover {
        box-shadow: 0 0 1px var(--input-border-hover);
    }

    .input:focus-visible {
        box-shadow: 0 0 3px var(--input-border-hover);
    }

    .input[type="color"] {
        padding: 3px 6px;
    }

    textarea.input {
        min-height: 40px;
        max-height: 256px; /* 10 rows */
    }

    select.input {
        padding-right: 42px;
        background-size: 14px 14px;
        background-position: right 14px center;
    }
}

