.plans {
  width: 920px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 60px;
  padding: 60px 80px;
}

.plans__panel {
  flex-grow: 1;
  flex-basis: 50%;
  display: flex;
  flex-direction: column;
  box-shadow: var(--shadow-over-white-background-stronger);
  border-radius: 30px;
  padding: 30px 30px 50px;
}

.plans__category {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--black);
  font-size: 30px;
  line-height: 40px;
  text-align: center;
  color: var(--brand);
}

.plans__price {
  margin-top: 20px;
  min-height: 68px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: var(--black);
  font-size: 28px;
  line-height: 34px;
  text-align: center;
}

.plans__price-item {
  margin-top: 4px;
}

.plans__price-item:first-child {
  margin-top: 0;
}

.plans__price--free {
  font-size: 36px;
  line-height: 40px;
  text-transform: uppercase;
}

.plans__contains {
  margin-top: 20px;
}

.plans__list {
  margin: 0;
  padding-left: 0;
  list-style: none;
  font-size: 16px;
  line-height: 21px;
}

.plans__feature {
  margin-top: 6px;
  padding-left: 26px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNMTMuMzg4MyAzLjQxNzU3QzEzLjcxIDMuNjMyMDEgMTMuNzk2OSA0LjA2NjYyIDEzLjU4MjUgNC4zODgyOUw4LjMwNzAxIDEyLjMwMTVDNy42ODYxMyAxMy4yMzI4IDYuMzUwMjMgMTMuMzIwNCA1LjYxMzE1IDEyLjQ3OEwzLjQ3MzI1IDEwLjAzMjRDMy4yMTg2OCA5Ljc0MTQ0IDMuMjQ4MTYgOS4yOTkyIDMuNTM5MTEgOS4wNDQ2M0MzLjgzMDA1IDguNzkwMDUgNC4yNzIyOCA4LjgxOTUzIDQuNTI2ODYgOS4xMTA0OEw2LjY2Njc2IDExLjU1NjFDNi43OTY4MyAxMS43MDQ3IDcuMDMyNTggMTEuNjg5MyA3LjE0MjE0IDExLjUyNDlMMTIuNDE3NiAzLjYxMTcxQzEyLjYzMjEgMy4yOTAwNCAxMy4wNjY3IDMuMjAzMTIgMTMuMzg4MyAzLjQxNzU3WiIvPjwvc3ZnPg==);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: left center;
}

.plans__action {
  margin-top: auto;
  padding-top: 40px;
  text-align: center;
}

.plans__action .button {
  width: 100%;
}

.plans__action--smaller-font-button .button {
  font-size: 22px;
}

@media (900px <= width < 1500px) {
  .plans {
    width: 740px;
    gap: 45px;
    padding: 45px 60px;
  }

  .plans__panel {
    border-radius: 22px;
    padding: 22px 15px 37px;
  }

  .plans__category {
    font-size: 22px;
    line-height: 30px;
  }

  .plans__price {
    margin-top: 20px;
    min-height: 51px;
    font-size: 24px;
    line-height: 25px;
  }

  .plans__price-item {
  }

  .plans__price-item:first-child {
  }

  .plans__price--free {
    font-size: 27px;
    line-height: 30px;
  }

  .plans__contains {
    margin-top: 22px;
  }

  .plans__list {
    font-size: 14px;
    line-height: 20px;
  }

  .plans__feature {
    margin-top: 4px;
  }

  .plans__action {
    padding-top: 30px;
  }

  .plans__action .button {
  }

  .plans__action--smaller-font-button .button {
    font-size: 19px;
  }
}
