/* Based on @geoapify/geocoder-autocomplete/styles/minimal.css
 * We needed to rewrite 95% of that style file, so instead of
 * making an override of those package styles we just create
 * a new CSS file with a completely new set of styles.
 * There is no need to add that file into the project anymore.
 */

.geoapify-autocomplete-input {
    vertical-align: top;
    width: 100%;
    height: 44px;
    padding: 9px 44px 9px 12px;
    background-color: #FFFFFF; /* reset grey background in some cases, such as <select> */
    background-clip: padding-box; /* hack for iOS to make box-shadow works (same as border-radius in the past) */
    border: 1px solid var(--input-border);
    outline: none;
  border-radius: var(--input-radius);
    font-family: inherit;
    font-size: 16px; /* not less than 16px (otherwise mobile browsers will zoom in on focus) */
    line-height: 24px;
    color: #000000;
    opacity: 1; /* reset select:disabled default fade on iOS */
    -webkit-tap-highlight-color: transparent;
    transition: background-color var(--transition-hover),
    border-color var(--transition-hover),
    box-shadow var(--transition-hover);
}


.geoapify-autocomplete-input::placeholder {
    opacity: 1; /* reset opacity drop Firefox */
    color: var(--placeholder-color);
}

.geoapify-autocomplete-input:hover {
    border-color: var(--input-border-hover);
    box-shadow: 0 0 1px var(--input-border-hover);
}

.geoapify-autocomplete-input:focus-visible {
    border-color: var(--input-border-hover);
    box-shadow: 0 0 4px var(--input-border-hover);
    transition: none;
}


.geoapify-autocomplete-items {
    position: absolute;
    z-index: 99;
    top: 100%;
    left: 0;
    right: 0;
    margin-top: 4px;
    border: none; /* override packages styles */
    padding-top: 8px;
    padding-bottom: 12px;
    background-color: #fff;
    box-shadow: var(--shadow-over-white-background);
    border-radius: 10px;
}

.geoapify-autocomplete-item {
    padding: 10px 10px 10px 0 !important;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.geoapify-autocomplete-item:hover {
    background-color: var(--hover-lighter) !important; /* !important is to override package styles */
}

.geoapify-autocomplete-items .active {
    background-color: var(--hover-lighter) !important; /* !important is to override package styles */
}

.geoapify-autocomplete-item .icon {
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: top;
    width: 50px;
    color: var(--grey);
}

.geoapify-autocomplete-item .icon.emoji {
    color: unset;
    font-size: 20px;
    opacity: 0.9;
}

.geoapify-autocomplete-items .address {
    font-size: 16px;
    line-height: 18px;
}

.geoapify-autocomplete-items .main-part {

}

.geoapify-autocomplete-items .main-part .non-verified {
    color: var(--danger);
}

.geoapify-autocomplete-items .secondary-part {
    margin-left: 10px;
    font-size: 14px;
    line-height: 18px;
    color: rgba(0, 0, 0, 0.6);
}


.geoapify-close-button {
    position: absolute;
    right: 0;
    top: 0;
    width: 44px;
    height: 100%;
    align-items: center;
    cursor: pointer;
    display: none;
    opacity: 1; /* override package styles */
}

.geoapify-close-button.visible {
    display: flex;
}

.geoapify-close-button:active {
    translate: 0 1px;
}

/* hover background */
.geoapify-close-button::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    margin: auto;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: background-color var(--transition-hover);
}

.geoapify-close-button::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: block;
    width: 100%;
    height: 100%;
    background-image: url("../assets/icons/cleartype.svg");
    background-size: 20px 20px;
    background-repeat: no-repeat;
    background-position: center center;
}

.geoapify-close-button:hover::before {
    background-color: var(--hover);
}

.geoapify-close-button svg {
    display: none !important;
}


/* Based on @geoapify/geocoder-autocomplete/styles/minimal.css
 * We needed to rewrite 95% of that style file, so instead of
 * making an override of those package styles we just create
 * a new CSS file with a completely new set of styles.
 * There is no need to add that file into the project anymore.
 */
@media (900px <= width < 1500px) {

    .geoapify-autocomplete-input {
        height: 40px;
        padding: 7px 10px;
        border-radius: 8px;
        font-size: 16px; /* not less than 16px (otherwise mobile browsers will zoom in on focus) */
        line-height: 24px;
    }

    .geoapify-autocomplete-input:hover {
        box-shadow: 0 0 1px var(--input-border-hover);
    }

    .geoapify-autocomplete-input:focus-visible {
        box-shadow: 0 0 3px var(--input-border-hover);
    }


    .geoapify-autocomplete-items {
        margin-top: 3px;
        padding-top: 6px;
        padding-bottom: 10px;
        border-radius: 8px;
    }

    .geoapify-autocomplete-item {
        padding: 8px 8px 8px 0 !important;
        cursor: pointer;
    }

    .geoapify-autocomplete-item .icon {
        width: 42px;
    }

    .geoapify-autocomplete-item .icon.emoji {
        font-size: 18px;
    }

    .geoapify-autocomplete-item .icon svg {
        height: 20px;
    }

    .geoapify-autocomplete-items .address {
        font-size: 15px;
    }

    .geoapify-autocomplete-items .secondary-part {
        margin-left: 8px;
        font-size: 13px;
    }


    .geoapify-close-button {
        width: 38px;
    }

    /* hover background */
    .geoapify-close-button::before {
        width: 28px;
        height: 28px;
    }

    .geoapify-close-button::after {
        background-size: 16px 16px;
    }
}
