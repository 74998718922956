.collapse {

}

.collapse__heading {
    margin-top: 0;
    margin-bottom: 0;
}

.collapse__handler {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: calc(100% + 24px);
    border: none;
    padding: 7px 12px;
    margin-left: -12px;
    margin-right: -12px;
    background-color: transparent;
    font-family: inherit;
    font-weight: var(--black);
    font-size: 16px;
    line-height: 22px;
    text-align: left;
    cursor: pointer;
    transition: background-color var(--transition-hover);
}

.collapse__handler:hover {
    background-color: var(--hover);
}

.collapse__angle {
    position: absolute;
    right: 12px;
    top: 50%;
    margin-top: -6px;
    width: 12px;
    height: 12px;
}


.collapse__dropdown {
    padding-bottom: 12px;
}
