.verpg-main {
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(7px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.verpg-inner-div {
  width: 384px;
  padding: 82px 42px 62px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  position: relative;
}

.c-icon {
  top: 15px;
    right: 15px;

    position: absolute;
    width: 25px;
    height: 25px;

  background-image: url("../../assets/icons/close-60.png");
    background-size: contain;
    background-repeat: no-repeat;

}

/* ---- */

.verpg-content {
    /* background-color: blueviolet; */
    margin-top: 20px;
    width: 85%;
    height: 80%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.verpg-h1 {
    font-weight: 700;
    font-size: 20px;
    margin-bottom: 25px;
}

.verpg-h2 {
    font-size: 14px;
    font-weight: 500;
    color: gray;
}

.verpg-h3 {
    font-size: 12px;
    font-weight: 500;
    color: gray;
    margin-top: 15px;
    display: flex;
}

.verpg-h3 div {
    margin-left: 5px;
    font-weight: 800;
    color: #FF780F;
}

.input-div {
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    padding-block: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.ver_code-input {
    width: 80%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid gray;
    padding-inline: 10px;
}

.submit-btn {
    margin-top: 30px;
    width: 90%;
    height: 45px;
    border-radius: 10px;
    background-color: #FF780F;
    border: none;
    border-radius: 50px;
    color: white;
    font-weight: bold;
    font-size: 18px;
}

.split-input > div {
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 14px;
}

.split-input input {
    text-align: center;
    width: 40px !important;
    font-size: 20px;
    border: 1px solid gray;
    border-radius: 10px;
    appearance: none;
}