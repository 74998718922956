html {
    height: 100%;
}



/* 1024px tablet.
 * There is no design for it, so we just let desktop version here.
 * min-width is necessary to create horizontal scrollbar that works like zoom
 */

@media ( 900px <= width < 1200px) {
    html {
        min-width: 1300px;
    }
}



/* 768px tablet
 * For this one we have a design and huge layout change happens here
 */

@media ( width < 900px) {
    html {
        min-width: 0;
    }
}



/* Smartphones
 * No design and not coded yet. Same table version applies here,
 * so there is no new code below
 */

@media ( width < 740px) {

}
