.mp-content-1 {
    width: 100%;
    margin: 0; /* Remove any margin */
  display: flex;
  justify-content: space-evenly; /* Space out the buttons */
  align-items: center;
}

.mv-content-2 {
  width: 100%;
  margin: 10px 0 0; /* Remove any margin */
  display: flex;
  justify-content: space-evenly; /* Space out the buttons */
  align-items: center;
}

.mv-pr,
.mv-qr {
  width: 45%; /* Adjust the width to fit within the card */
  height: 40px; /* Adjust height */
  margin: 0; /* Remove any margin */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: white;
  cursor: pointer;
  pointer-events: auto;
}

.mp-edit {
  width: 45%; /* Adjust the width to fit within the card */
  height: 40px; /* Adjust height */
  margin: 0; /* Remove any margin */
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: gray;
  color: white;
}

.mv-img-1 {
  margin-right: 5px;
  background-image: url("../../assets/icons/icons8-qr-96.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.mv-img-2 {
  background-image: url("../../assets/icons/icons8-file-download-96.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 25px;
  height: 25px;
}

.mp-content {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

.mp-page-content-card {
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: space-between; /* This will distribute space evenly */
    padding: 15px; /* Add padding for inner spacing */
    cursor: pointer;
}

.mp-page-content-card:hover {
  box-shadow: 0 0 10px rgba(255, 123, 15, 0.6);
}

.mp-selected-card {
    border: 5px solid #FF7B0F;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px #FF7B0F;
    display: flex;
    justify-content: space-between; /* This will distribute space evenly */
    /* align-items: center; */
    flex-direction: column;
    padding: 15px; /* Add padding for inner spacing */
}


.img-title {
    display: flex;
    align-items: center;
    /* justify-content: center; */
    margin-left: 20px;
    margin-top: 10px;
    /* background-color: aqua; */
}

.img-banner {
    min-width: 90px;
    min-height: 90px;
    max-width: 90px;
    max-height: 90px;
    /* background-color: brown; */
    border-radius: 7px;
    background-size: contain;
    background-position: 0%;
    /* margin: 10%; */
    /* margin-left: 0%; */
    justify-self: flex-start;
}

.img-banner img {
    width: 100%; /* Make the image occupy the entire width of its container */
    height: 100%; /* Maintain the image's aspect ratio */
    border-radius: 7px;
}

.title-name {
    width: 80%;
    font-size: 20px;
    /* background-color: aqua; */
    margin-left: 6%;
    font-weight: 800;
}

.title-store {
    width: 80%;
    font-size: 12px;
    margin-left: 10px;
    font-weight: 700;
    color: gray;
}

.loc-name {
    margin-top: 5px;
    width: 80%;
    font-size: 10px;
    margin-left: 10px;
}

.content {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: blue; */
    margin-bottom: 10px;
    margin-top: 10px;
}

.content div {
    width: 85%;
    font-size: 10px;
    text-align: justify;
}

.btns {
    /* background-color: aquamarine; */
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}

.btns button {
    padding: 10px 35px;
    border: none;
    border-radius: 7px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.select-btn {
    width: 45%; /* Adjust the width to fit within the card */
    height: 40px; /* Adjust height */
    margin: 0; /* Remove any margin */
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    border-radius: 5px;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);

  background-color: var(--primary-colour);
  color: white;
}
