/* This file includes all the necessary code for generating stand-alone PDF document */

* {
    box-sizing: border-box;
}

.poster {
    position: relative;
    width: 794px;
    height: 1122px;
    background-color: #FFFFFF;
    background-image: linear-gradient(transparent, var(--brand));
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.poster__background-noise {
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 0 64px 32px;
    background-image: url("images/noise.png");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
}

.poster__aligning {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.poster__head {
    display: flex;
    align-items: flex-start;
}

.poster__join {
    width: 304px;
    height: 304px;
    margin: -10px;
    padding: 25px;
    background-image: url("images/logo-background.png");
    background-size: 304px 304px;
}

.poster__join img {
    width: 100%;
    height: auto;
}

.poster__summary {
    margin-top: 4px;
    margin-left: 46px;
    width: 324px;
    height: 280px;
    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden;
    background-image: url("images/headings.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
}

.poster__cut-card {
    margin: 52px -10px -10px;
    overflow: hidden; /* a1) Dirty hack. Trying to cut visual bug of background image at the bottom that leaves half transparent lin. Cutting 2px at the bottom. */
}

.poster__card {
    position: relative;
    min-height: 460px; /* 443px is real min-height, but we add 17px just so it looks better visually when smallest size reach */
    margin-bottom: -2px; /* a2) Dirty hack. Trying to cut visual bug of background image at the bottom that leaves half transparent lin. Cutting 2px at the bottom. */
}

.poster__middle-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 100px;
    bottom: 100px;
    background-image: url("images/card-background-middle.png");
    background-size: 686px 30px;
    background-repeat: repeat-y;
    background-position: center center;
}

.poster__top-background {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 100px;
    background-image: url("images/card-background-top.png");
    background-size: 686px 100px;
    background-repeat: no-repeat;
    background-position: center bottom;
}

/* b1) Dirty hack. A patch. It lies over visual bug in the are where middle image connects with top one */
.poster__top-background::after {
    content: "";
    position: absolute;
    left: 10px;
    right: 10px;
    top: 100%;
    height: 4px;
    margin-top: -2px;
    background-color: #FFFFFF;
}

.poster__bottom-background {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100px;
    background-image: url("images/card-background-bottom.png");
    background-size: 686px 100px;
    background-repeat: no-repeat;
    background-position: center top;
}

/* b2) Dirty hack. A patch. It lies over visual bug in the are where middle image connects with bottom one */
.poster__bottom-background::after {
    content: "";
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 100%;
    height: 4px;
    margin-bottom: -2px;
    background-color: #FFFFFF;
}

.poster__inner-card {
    position: relative;
    padding: 56px 36px 116px;
}

.poster__about {
    display: flex;
    align-items: stretch;
    gap: 36px;
}

.poster__logo {
    height: 266px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.poster__logo img {
    height: 100%;
    width: auto;
    max-width: 320px;
    border-radius: 16px;
    object-fit: contain;
}

.poster__data {
    margin-top: 6px;
    display: flex;
    flex-direction: column;
}

.poster__name {
    font-weight: 900;
    font-size: 60px;
    line-height: 73px;
    text-wrap: balance;
}

.poster__location {
    margin-top: auto;
    padding-left: 46px;
    border-top: 40px solid transparent;
    background-image: url("images/location.png");
    background-size: 28px 33px;
    background-repeat: no-repeat;
    background-position: 3px center;
    font-size: 24px;
    line-height: 32px;
}

.poster__footer {
    flex-shrink: 0;
    display: flex;
    align-items: center;
}

.poster__footer::before {
    content: "";
    align-self: center;
    width: 200px;
    height: 85px;
    margin-right: auto;
    background-image: url("images/copyright.png");
    background-size: 200px 85px;
    background-repeat: no-repeat;
    background-position: left center;
}

.poster__stores {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.poster__store-apple {
    vertical-align: top;
    width: 160px;
    height: auto;
}

.poster__store-google {
    vertical-align: top;
    width: 160px;
    height: auto;
}

.poster__meed-qr {
    margin-left: 14px;
    height: 110px;
    border-radius: 6px;
}