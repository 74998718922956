.show-all-programs {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 300px;
  margin-left: -12px;
  margin-right: -12px;
  background-color: transparent;
  border-radius: 6px;
  padding: 11px 24px;
  border: none;
  font-family: inherit;
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  cursor: pointer;
}

.show-all-programs:hover {
  background-color: var(--hover-lighter);
}

.show-all-programs__label {
}

.show-all-programs__icon {
  margin-top: 4px;
  margin-bottom: 2px;
}

.show-all-programs--horizontal {
  padding-top: 14px;
  padding-bottom: 14px;
  flex-direction: row;
  justify-content: center;
}

.show-all-programs--horizontal .show-all-programs__label {
}

.show-all-programs--horizontal .show-all-programs__icon {
  width: 14px;
  margin-top: 1px;
  margin-bottom: 0;
  margin-left: 4px;
  transform: rotate(-90deg);
}
