.login-main {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(7px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.login-inner-div {
  width: 384px;
  padding: 102px 42px;
  min-height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  position: relative;
}

.c-icon {
  top: 15px;
  right: 15px;

  position: absolute;
  width: 25px;
  height: 25px;

  background-image: url("../../assets/icons/close-60.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.login-h1 {
  font-weight: 700;
  font-size: 46px;
  line-height: 46px;
  margin-inline: 36px;
  margin-top: 94px;
}

.login-h2 {
  font-size: 30px;
  line-height: 30px;
  margin-inline: 36px;
}

.login-p {
    color: gray;
    margin-bottom: 20px;
    margin-inline: 36px;
    font-size: 14px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-inline: 36px;
}

.turnstile {
    align-items: center;
    height: 0;
}

.turnstile--interactive {
    margin-top: 24px;
    height: auto;
}

.email-input {
    height: 40px;
    border-radius: 10px;
    border: 1px solid gray;
    padding-inline: 10px;

    margin-top: 3%;
}

.submit-btn {
    height: 45px;
    width: 100%;
    border-radius: 10px;
    background-color: #FF780F;
    border: none;
    color: white;
    font-weight: bold;
    font-size: 18px;
    margin-top: 3%;
}

.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 354;
    margin-inline: 20px;
}

.division {
    display: flex;
    width: 95%;
    justify-content: center;
    align-items: center;
    height: 20px;
    margin-top: 6%;
    margin-left: 3%;
}

.hr-line {
    width: 136px;
    margin: 2%;
}

.apple {
  margin-top: 9%;
  width: 100%;
  height: 58px;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/icons/apple_signin.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  background-color: white;
  /* White background */
  border: none;
}

.facebook {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  /* background-color: #FF780F; */
  background-image: url("../../assets/icons/icons8-facebook-48.png");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin: 3%;
  border: none;
}

.google {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  /* background-color: #FF780F; */
  background-image: url("../../assets/icons/icons8-google-48.png");
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 20px;
  margin: 3%;
  border: none;
}
