.detailed-preview {
    padding: 14px 28px;
}

.detailed-preview__back {
    margin-top: 0;
    margin-bottom: 0;
    display: flex;
    align-items: flex-start;
    flex-shrink: 0;
    font-weight: var(--black);
    font-size: 28px;
    line-height: 32px;
}

.detailed-preview__chevron {
    vertical-align: top;
    margin-top: 7px;
    margin-right: 9px;
    width: 11px;
    height: 18px;
}

.detailed-preview__body {
    margin-top: 12px;
}
