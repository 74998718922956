.lp-main-div {
  width: 100vw;
  height: 100vh;
  position: relative;
  justify-content: center; /* Center horizontally */
  align-items: flex-start; /* Align to top */
  margin-left: -8px;
}

body::-webkit-scrollbar {
  display: none;
}

.lp-div1 {
  position: absolute;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  justify-content: center; /* Center horizontally */
}

.lp-div2 {
  position: absolute;
  width: 100vw;
  height: 100vh;
  justify-content: center; /* Center horizontally */
}

.lp-bg {
  width: 100vw;
  height: 90vh;
  /* background: linear-gradient(to bottom right, #DA4509, #FFDA6C); */
  background-image: url("../../../assets/images/group_126.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lp-innermain {
  width: 100vw;
  height: 91.63%;
  background: linear-gradient(to bottom right, #da4509, #ffda6c);

  display: flex;
  justify-content: center;
  align-items: center;
}

.lp-logo {
  width: 14.16%;
  height: 40.64%;
  background-image: url("../../../assets/images/logo1.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.lp-text {
  width: 43%;
  padding-top: 20px;
  margin-left: 7.5%;
  /* font-family: Nunito sans; */
}

.h1,
.h2 {
  color: white;
  text-shadow: 0px 4px 4px #00000040;
  font-family: "Nunito Sans-ExtraBold", Helvetica;
}

.h1 {
  font-weight: 900;
  font-size: 64px;
}

.h2 {
  width: 317px;
  height: 85px;
  font-size: 36px;
  font-weight: 600;
  line-height: 90px;
}

.lp-text p {
  font-size: 16px;
  margin-top: 20px;
}

.lp-label {
  width: 826px;
  height: 66px;
}

.lp-statement {
  position: fixed;
  width: 826px;
  top: 0;
  left: 0;
  transform: rotate(-0.01deg);
  font-family: "Nunito Sans-Light", Helvetica;
  font-weight: 300;
  color: #000000cc;
  font-size: 28px;
  letter-spacing: 0;
  line-height: normal;
}

.lp-media {
  position: absolute;
  bottom: 7%;
  z-index: 1;
  width: 85%;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lp-media-text {
  color: white;
}

.media {
  display: flex;
}

#m-link {
  width: 35px;
  height: 35px;

  background-color: white;
  border-radius: 50%;
  margin-inline: 5px;

  display: flex;
  justify-content: center;
  align-items: center;
}

.lp-m1 {
  width: 25px;
  height: 25px;

  background-image: url("../../../assets/icons/icons8-twitter-50.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.lp-m2 {
  width: 25px;
  height: 25px;

  background-image: url("../../../assets/icons/icons8-linkedin-90.png");
  background-size: cover;
  background-repeat: no-repeat;
}

.frame {
  display: inline-flex;
  align-items: center;
  gap: 144px;
  position: relative;
  flex: 0 0 auto;
}

.meed-logov-w {
  position: relative;
  width: 272px;
  height: 478.78px;
  object-fit: cover;
}

.text-frame {
  display: inline-flex;
  flex-direction: column;
  height: 355px;
  align-items: flex-start;
  justify-content: center;
  position: relative;
  flex: 0 0 auto;
}

.unlock-the-power-of {
  position: relative;
  width: 826px;
  height: 236px;
  text-shadow: 0px 4px 4px #00000040;
  font-family: "Nunito Sans-SemiBold", Helvetica;
  font-weight: 400;
  color: #ffffff;
  font-size: 62px;
  letter-spacing: 0;
  line-height: 62px;
}

.unlock-the {
  font-weight: 600;
  line-height: 90px;
  font-size: 82px;
}

.power-of-loyalty {
  font-family: "Nunito Sans-Black", Helvetica;
  font-weight: 900;
  font-size: 100px;
  line-height: 90px;
  text-overflow: inherit;
  white-space: nowrap;
}

.for-your-business {
  font-weight: 600;
  line-height: 70px;
  font-size: 82px;
}

.lp-statement-label {
  position: relative;
  width: 826px;
  height: 66.07px;
  transform: rotate(-0.01deg);
  font-family: "Nunito Sans-Light", Helvetica;
  font-weight: 300;
  color: #000000cc;
  font-size: 28px;
  letter-spacing: 0;
  line-height: normal;
}

@media only screen and (min-width: 601px) and (max-width: 962px) {
  .lp-innermain {
    flex-direction: column;
  }

  .lp-logo {
    width: 30%;
    height: 30%;
  }

  .lp-text {
    width: 70%;
    padding-top: 10px;
    margin-left: 0;
    margin-top: 2%;
    text-align: center;
  }

  .h1 {
    font-size: 50px;
  }

  .h2 {
    font-size: 28px;
  }

  .lp-text p {
    font-size: 14px;
    margin-top: 20px;
  }

  .lp-media {
    width: 90%;
  }

  #m-link {
    width: 30px;
    height: 30px;
  }

  .lp-m1,
  .lp-m2 {
    width: 20px;
    height: 20px;
  }
}
