.analytics-main {
  width: 100%;
  height: 100%;
  font-family: "Nunito Sans", sans-serif;
  background-color: #f7f6fb;
  /* align-items: center;
    justify-content: center; */
}

.graph1-heading {
  height: 20%;
  background-color: var(--primary-colour);
  border-radius: 15px 15px 0 0;
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
  padding: 2%;
}

.graph2-heading {
  width: 96%;
  height: 20%;
  /* background-color: var(--primary-colour); */
  /* border-radius: 15px 15px 0 0; */
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.25);
  padding: 2%;
}

.cl-div2 {
  font-size: 30px;
  font-weight: 900;
  margin-left: 15%;
}

.dash {
  width: 94%;
  height: 100%;
  margin-left: 1%;
  border-radius: 15px;
  display: flex;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  background-color: white;
}

.cl-containers4 {
  width: 75%;
  height: 20%;
  /* margin-bottom: 3%; */
  margin-top: 19px;
  margin-left: 13.5%;
  justify-content: space-evenly;
  box-sizing: border-box;
}

.topic {
  font-size: 25px;
  font-weight: bold;
  margin-left: 2%;
}

.butns {
  width: 100%;
  height: 50%;
  /* background-color: aqua; */
  margin-top: 2%;
  display: flex;
}

.an-btns {
  background-color: white;
  border: 1px solid #000000;
  border-radius: 20px;
  margin-left: 2%;
  width: 160px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.an-btns1 {
  background-color: white;
  border: white;
  border-radius: 20px;
  margin-left: 2%;
  width: 160px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.graph {
  width: 90%;
  height: 70%;
  margin-left: 7%;
  align-items: center;
  justify-content: center;
  /* background-color: aquamarine; */
  background-image: url("../../assets/images/Group\ 236\ 1.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.th {
  border-bottom: 1px solid grey;
}

.graph-1 {
  padding: 15px 15px 15px 0;
  box-sizing: border-box;
  /* background-color: aquamarine; */
}

.result-an {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.coming-soon {
  height: calc(100vh - 300px);
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 15%;
  margin-right: 15%;
  font-family: var(--font);
  font-weight: 300;
  font-size: 100px;
  line-height: 100px;
  color: #c9c9c9;
  text-align: center;
}

/*  */

.analytics-graphs {
  height: calc(100vh - 200px);
  min-height: 400px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-left: 15%;
  margin-right: 15%;
  font-family: var(--font);
  font-weight: 300;
  color: #c9c9c9;
  text-align: center;
}

.analytics-display-value {
  width: 100%;
  height: 30%;
  /* background-color: white; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-block: 10px;
}

.display-value {
  height: 100%;
  width: 22%;
  background-color: white;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 36px;
}

.analytics-graph {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: white;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: 15px;
  margin-block: 10px;
}

.filterdiv {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.DatePickerDiv {
  background-color: white;
  box-shadow: var(--shadow-over-grey-background);
  border-radius: 15px;
  border: none;
  text-align: center;
  padding-inline: 20px;
  padding-block: 8px;
  color: #000000;
  cursor: pointer;
}

.DayPickerPopUp {
  position: absolute;
  background-color: #ffffff;
  border-radius: 15px;
  border: 1px solid #f87c56;
  z-index: 9999;
  padding: 10px;
  color: #000000;
  left: 0%;
  top: 0%;
}

.rdp-day_selected {
  background-color: #f87c56;
}

.rdp-day_selected:hover {
  background-color: #f87c56;
}

/* .DayPicker-Day--selectedRange {
  background-color: lightblue;
  color: black;
} */

.radio-group {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  box-shadow: var(--shadow-over-grey-background);
}

.radio-div {
  padding-inline: 15px;
  padding-block: 8px;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
}

.radio-div:hover {
  background-color: #f87c56;
  color: white;
}

.radio-div:first-child {
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
}

.radio-div:last-child {
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
}

.radio-div:not(:first-child) {
  border-left: 1px solid #eee;
  /* Add left border except for the first one */
}

.filter__range {
  display: flex;
}

.refresh-button {
  border-radius: 15px;
  box-shadow: var(--shadow-over-grey-background);
  background-color: white;
  padding-inline: 15px;
  border: none;
  cursor: pointer;
}

.refresh-button:hover {
  background-color: #c9c9c9;
}

/* #f87c56 */
