.header {
  position: fixed;
  z-index: 1000;
  top: 8px;
  right: 14px;
  padding: 4px 4px 4px 20px;
  background-color: #faf9fc;
  box-shadow: 0 1px 2px rgba(0 0 0 / 10%);
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.header__premium {
}

.header__plans {
  margin-left: 4px;
}

.header__plans .button__icon {
  margin-left: -4px;
}

.header__user {
}

@media (900px <= width < 1500px) {
  .header {
    top: 8px;
    right: 12px;
    border-radius: 6px;
    gap: 8px;
  }
}
