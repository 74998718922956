.pdf-page {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: #000000;
}

.pdf-page__container {
    padding-top: 40px;
    padding-bottom: 40px;
    display: flex;
    width: fit-content;
    margin-left: auto;
    margin-right: auto;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    background-color: #000000;
}

.pdf-page__actions {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.pdf-page__back {
    position: absolute;
    left: 0;
    top: 0;
    display: inline-block;
    vertical-align: top;
    padding: 13px 0;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-decoration: none;
}

.pdf-page__handler {
    display: inline-block;
    vertical-align: top;
    border: none;
    padding: 13px 24px;
    min-width: 100px;
    background-color: #FF7B0F;
    border-radius: 4px;
    font-family: inherit;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    transition: background-color 100ms ease;
}

.pdf-page__handler:hover {
    background-color: #F7B33B;
}

.pdf-page__handler:active {
    translate: 0 1px;
    transition: none;
}

.pdf-page__viewport {
    margin-top: 40px;
}


/* 1) Actual size as if font was 16px */
@media (max-height: 649px) {
    .pdf-page__viewport {
        zoom: 0.5;
    }
}

/* 2) Trying to fit it vertically to avoid scrollbar
 * 170px here is a total of: 3✕40px of vertical margins + 50px of button height
 * 1122px is a height of .poster
 */
@media (min-height: 650px) and (max-height: 699px) {
    .pdf-page__viewport {
        zoom: calc((650 - 170) / 1122);
    }
}

@media (min-height: 700px) and (max-height: 749px) {
    .pdf-page__viewport {
        zoom: calc((700 - 170) / 1122);
    }
}

@media (min-height: 750px) and (max-height: 799px) {
    .pdf-page__viewport {
        zoom: calc((750 - 170) / 1122);
    }
}

@media (min-height: 800px) and (max-height: 849px) {
    .pdf-page__viewport {
        zoom: calc((800 - 170) / 1122);
    }
}

@media (min-height: 850px) and (max-height: 899px) {
    .pdf-page__viewport {
        zoom: calc((850 - 170) / 1122);
    }
}

@media (min-height: 900px) and (max-height: 949px) {
    .pdf-page__viewport {
        zoom: calc((900 - 170) / 1122);
    }
}

@media (min-height: 950px) and (max-height: 999px) {
    .pdf-page__viewport {
        zoom: calc((950 - 170) / 1122);
    }
}

@media (min-height: 1000px) and (max-height: 1049px) {
    .pdf-page__viewport {
        zoom: calc((1000 - 170) / 1122);
    }
}

@media (min-height: 1050px) and (max-height: 1099px) {
    .pdf-page__viewport {
        zoom: calc((1050 - 170) / 1122);
    }
}

@media (min-height: 1100px) and (max-height: 1149px) {
    .pdf-page__viewport {
        zoom: calc((1100 - 170) / 1122);
    }
}

@media (min-height: 1150px) and (max-height: 1199px) {
    .pdf-page__viewport {
        zoom: calc((1150 - 170) / 1122);
    }
}

@media (min-height: 1200px) and (max-height: 1249px) {
    .pdf-page__viewport {
        zoom: calc((1200 - 170) / 1122);
    }
}

@media (min-height: 1250px) and (max-height: 1299px) {
    .pdf-page__viewport {
        zoom: calc((1250 - 170) / 1122);
    }
}

@media (min-height: 1300px) {
    .pdf-page__viewport {
        zoom: 1;
    }
}