.label {
  display: block; /* In case it is being followed by button or smaller input. We want it to be on a new line. */
  width: fit-content; /* Specify clickable area */
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 8px;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.label--smaller {
  padding-bottom: 6px;
  font-size: 16px;
  line-height: 22px;
}

@media (900px <= width < 1500px) {
  .label {
    font-size: 15px;
    line-height: 18px;
    padding-bottom: 6px;
  }

  .label--smaller {
    padding-bottom: 4px;
    font-size: 13px;
    line-height: 16px;
  }
}
