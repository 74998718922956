* {
    box-sizing: border-box;
}

*::before {
    box-sizing: border-box;
}

*::after {
    box-sizing: border-box;
}
