.pagination {
    display: flex;
    align-items: baseline;
    justify-content: center;
    gap: 12px;
}
    .pagination__separator {
        padding-left: 12px;
        padding-right: 12px;
    }
