.filter {

}

.filter__ribbon {
    display: inline-flex;
    align-items: flex-start;
    gap: 12px;
}

.filter__bar {
    display: inline-flex;
    align-items: stretch;
    box-shadow: var(--shadow-over-white-background);
    border-radius: 5px;
}

.filter__item {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    vertical-align: top;
    min-width: 100px;
    padding: 9px 24px;
    border: 1px solid transparent;
    border-left: 1px solid #F0F0F0;
    margin-left: -1px;
    background-color: #FFFFFF;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
    user-select: none;
    transition: background-color var(--transition-hover),
    border-color var(--transition-hover),
    box-shadow var(--transition-hover),
    color var(--transition-hover);
}


.filter__item:hover {
    border-left-color: transparent;
    background-color: var(--primary-colour-hover);
    color: #FFFFFF;
}

.filter__item:hover + .filter__item {
    border-left-color: transparent;
}

.filter__item:first-child {
    margin-left: 0;
    border-left: none;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
}

.filter__item:last-child {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.filter__item--selected {
    background-color: var(--primary-colour);
    color: #FFFFFF;
}

.filter__item--selected + .filter__item {
    border-left-color: transparent;
}

.filter__refresh {

}


@media (900px <= width < 1500px) {

    .filter {

    }

    .filter__ribbon {
        /*display: inline-flex;*/
        /*align-items: flex-start;*/
        /*gap: 12px;*/
    }

    .filter__bar {
        /*display: inline-flex;*/
        /*align-items: stretch;*/
        /*box-shadow: var(--shadow-over-white-background);*/
        border-radius: 4px;
    }

    .filter__item {
        /*display: inline-flex;*/
        /*align-items: center;*/
        /*justify-content: center;*/
        /*gap: 10px;*/
        /*vertical-align: top;*/
        min-width: 75px;
        padding: 9px 18px;
        /*border: 1px solid transparent;*/
        /*border-left: 1px solid #F0F0F0;*/
        /*margin-left: -1px;*/
        /*background-color: #FFFFFF;*/
        /*font-family: inherit;*/
        /*font-weight: 600;*/
        font-size: 15px;
        line-height: 20px;
        /*color: #000000;*/
        /*text-align: center;*/
        /*text-decoration: none;*/
        /*white-space: nowrap;*/
        /*cursor: pointer;*/
        /*user-select: none;*/
        /*transition: background-color var(--transition-hover),*/
        /*            border-color var(--transition-hover),*/
        /*            box-shadow var(--transition-hover),*/
        /*            color var(--transition-hover);*/
    }


    .filter__item:hover {
        /*border-left-color: transparent;*/
        /*background-color: var(--primary-colour-hover);*/
        /*color: #FFFFFF;*/
    }

    .filter__item:hover + .filter__item {
        /*border-left-color: transparent;*/
    }

    .filter__item:first-child {
        /*margin-left: 0;*/
        /*border-left: none;*/
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
    }

    .filter__item:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
    }

    .filter__item--selected,
    .filter__item--selected:hover {
        /*background-color: var(--primary-colour);*/
        /*color: #FFFFFF;*/
    }

    .filter__item--selected + .filter__item {
        /*border-left-color: transparent;*/
    }

    .filter__refresh {

    }
}
