.landing {
    width: 100%;
    height: 100%;
    display: table;
    table-layout: fixed;
    background-image: url("../images/landing-background.jpg");
    background-size: 1920px 1080px;
    background-repeat: no-repeat;
    background-position: center center;
}

@media (1080px < width) or (1080px < height) {
    .landing {
        background-size: cover;
    }
}

@media (min-resolution: 2dppx) {
    .landing {
        background-image: url("../images/landing-background@2x.jpg");
    }
}

@media (min-resolution: 3dppx) {
    .landing {
        background-image: url("../images/landing-background@3x.jpg");
    }
}

.landing__header {
    display: table-row;
    background-color: #FFFFFF;
}

.landing__header-wrap {
    height: 88px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.landing__brand {
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.landing__logo {
    display: block;
    width: 161px;
    height: 50px;
    background-image: url("../images/landing-header-logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    text-indent: 200%;
    white-space: nowrap;
    overflow: hidden;
}

@media (min-resolution: 2dppx) {
    .landing__logo {
        background-image: url("../images/landing-header-logo@2x.png");
    }
}

@media (min-resolution: 3dppx) {
    .landing__logo {
        background-image: url("../images/landing-header-logo@3x.png");
    }
}

.landing__version {
    margin-top: 34px;
    font-weight: 500;
    font-size: 15px;
    line-height: 20px;
}

.landing__actions {
    display: flex;
    align-items: center;
    gap: 28px;
}

.landing__actions .button {
    width: auto;
}

.landing__body {
    display: table-row;
    height: 100%;
}

.landing__body-wrap {
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 80px;
}

.landing__stage {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 1242px;
    min-height: 461px;
    padding-left: 416px;
    background-image: url("../images/landing-logo.png");
    background-size: 272px 461px;
    background-repeat: no-repeat;
    background-position: left center;
}

@media (min-resolution: 2dppx) {
    .landing__stage {
        background-image: url("../images/landing-logo@2x.png");
    }
}

@media (min-resolution: 3dppx) {
    .landing__stage {
        background-image: url("../images/landing-logo@3x.png");
    }
}

.landing__heading {
    color: #FFFFFF;
    text-shadow: var(--shadow-over-grey-background);
}

.landing__unlock {
    display: block;
    font-size: 62px;
    line-height: 62px;
}

.landing__power {
    display: block;
    font-size: 100px;
    line-height: 94px;
}

.landing__business {
    display: block;
    font-size: 62px;
    line-height: 70px;
}

.landing__description {
    margin-top: 20px;
    font-size: 28px;
    line-height: 32px;
    color: rgb(0 0 0 / 80%);
}

.landing__footer {
    display: table-row;
}

.landing__footer-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 178px;
}

.landing__copyright {
    font-weight: 600;
    font-size: 17px;
    line-height: 24px;
    color: #FFFFFF;
}

.landing__media {
    display: flex;
    align-items: stretch;
    gap: 14px;
}

.landing__social-network {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    background-color: #FFFFFF;
    border-radius: 50%;
    transition: background-color var(--transition-hover);
}

.landing__net {
    vertical-align: top;
    width: 22px;
    height: 22px;
    fill: var(--brand);
    transition: fill var(--transition-hover);
}

.landing__social-network:hover {
    background-color: var(--primary-colour-hover);
}

.landing__social-network:hover .landing__net {
    fill: #FFFFFF;
}


@media (900px <= width < 1500px) {

    .landing {

    }

    .landing__header {

    }

    .landing__header-wrap {
        height: 66px;
    }

    .landing__brand {
        gap: 15px;
    }

    .landing__logo {
        width: 121px;
        height: 36px;
    }

    .landing__version {
        margin-top: 22px;
        font-size: 13px;
        line-height: 18px;
    }

    .landing__actions {
        gap: 21px;
    }

    .landing__body {

    }

    .landing__body-wrap {
        padding-top: 50px;
    }

    .landing__stage {
        width: 932px;
        min-height: 346px;
        padding-left: 312px;
        background-size: 204px 346px;
    }

    .landing__heading {

    }

    .landing__unlock {
        font-size: 47px;
        line-height: 47px;
    }

    .landing__power {
        font-size: 75px;
        line-height: 72px;
    }

    .landing__business {
        font-size: 47px;
        line-height: 53px;
    }

    .landing__description {
        margin-top: 15px;
        font-size: 21px;
        line-height: 24px;
    }

    .landing__footer {

    }

    .landing__footer-wrap {
        height: 134px;
    }

    .landing__copyright {
        font-size: 15px;
        line-height: 20px;
    }

    .landing__media {
        gap: 11px;
    }

    .landing__social-network {
        width: 38px;
        height: 38px;
    }

    .landing__net {
        width: 17px;
        height: 17px;
    }

    .landing__social-network:hover {

    }

    .landing__social-network:hover .landing__net {

    }
}
@media (width < 900px) {
  .landing {
    background-size: cover;
  }

  .landing__header {
  }

  .landing__header-wrap {
  }

  .landing__brand {
  }

  .landing__logo {
  }

  .landing__version {
  }

  .landing__actions {
  }

  .landing__actions .button {
  }

  .landing__body {
  }

  .landing__body-wrap {
    padding-top: 65px;
  }

  .landing__stage {
    width: auto;
    padding-left: 0;
    padding-top: 500px;
    background-position: left top;
  }

  .landing__heading {
    margin-top: 34px;
    margin-bottom: 0;
  }

  .landing__unlock {
    font-size: 32px;
    line-height: 40px;
  }

  .landing__power {
    margin-left: -3px;
    font-size: 82px;
    line-height: 82px;
  }

  .landing__business {
    margin-top: 7px;
    font-size: 32px;
    line-height: 32px;
  }

  .landing__description {
    margin-top: 35px;
    font-size: 24px;
    line-height: 33px;
  }

  .landing__footer {
  }

  .landing__footer-wrap {
    height: 150px;
  }

  .landing__copyright {
  }

  .landing__media {
  }

  .landing__social-network {
  }

  .landing__net {
  }

  .landing__social-network:hover {
  }

  .landing__social-network:hover .landing__net {
  }
}
