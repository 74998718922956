.download {
  width: 670px;
  padding: 40px;
  border-radius: 30px;
  display: flex;
  align-items: center;
  gap: 30px;
}

.download__qr {
  width: 178px;
  flex-shrink: 0;
}

.download__description {
  font-size: 18px;
  line-height: 26px;
}

.download__intro {
}

.download__list {
  margin-top: 6px;
  margin-bottom: 0;
  padding-left: 20px;
}

.download__item {
}
