.pattern {
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.pattern__gradient {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  transform-origin: 50% 50%;
}
