.label-alike-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  vertical-align: top;
  padding: 8px 0;
  border: 1px solid transparent;
  background-color: transparent;
  font-family: inherit;
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
  color: inherit;
  text-align: center;
  text-decoration: none;
  white-space: nowrap;
  cursor: pointer;
  user-select: none;
  transition:
    background-color var(--transition-hover),
    border-color var(--transition-hover),
    box-shadow var(--transition-hover),
    color var(--transition-hover);
}

.label-alike-button:hover {
  color: var(--brand);
}
