.popup {
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0 0 0 / 50%);
  backdrop-filter: blur(20px);
}

.popup__container {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
}

.popup__viewport {
  display: table-cell;
  width: 100%;
  height: 100%;
  text-align: center;
  vertical-align: middle;
}

.popup__slot {
  position: relative;
  display: inline-block;
  vertical-align: top;
  text-align: left;
  margin: 20px;
}

.popup__window {
  background-color: #ffffff;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  border-radius: 15px;
}

.popup__close {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
  padding: 10px;
  border-radius: 50%;
}

.popup__cross {
  fill: #5d6164;
}

.popup__close:hover .popup__cross {
  fill: var(--brand);
}

.popup__close:active .popup__cross {
  translate: 0 1px;
}

.popup--rounded-framed {
}

.popup--rounded-framed .popup__window {
  border-radius: 30px;
  border: 4px solid var(--brand);
}
