@import "~react-day-picker/dist/style.css";

.voucher__section--focus {
  box-shadow:
    0 0 0 5px #ff7b0f,
    0 0 5px 5px #ff7b0f;
}

.voucher__choice .form {
  margin: 0;
}

.voucher__choice .form-content {
  width: 100%;
}

.discount {
  margin-left: 53%;
  margin-top: 18%;
  align-items: center;
  background-image: url("../../assets/icons/Discount_Voucher.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.discount:hover {
  background-image: url("../../assets/icons/discount voucher_white.png");
}

.cash {
  margin-left: 53%;
  margin-top: 18%;
  align-items: center;
  background-image: url("../../assets/icons/Cash_Voucher.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.cash:hover {
  background-image: url("../../assets/icons/cash_voucher_white.png");
}

.giveaway {
  margin-left: 55%;
  margin-top: 20%;
  align-items: center;
  background-image: url("../../assets/icons/giveaway.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.giveaway:hover {
  background-image: url("../../assets/icons/giveaway_white.png");
}

.enevt {
  margin-left: 55%;
  margin-top: 20%;
  align-items: center;
  background-image: url("../../assets/icons/Event_Pass.png");
  background-size: contain;
  background-repeat: no-repeat;
}

.enevt:hover {
  background-image: url("../../assets/icons/event_pass_white.png");
}

.voucher__types-hint {
  margin-top: 12px;
  margin-left: 32px;
  margin-right: 32px;
}

.voucher__type--disabled {
  opacity: 0.8;
}

.voucher__type--disabled .voucher__tile {
  pointer-events: none;
}

.type-warning {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 2%;
}

.warning {
  width: 90%;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 5px;
  background-color: lightgray;

  display: flex;
  justify-content: space-between;
  align-items: center;
}

.warning-icon {
  width: 40px;
  height: 40px;
  background-image: url("../../assets/icons/icons8-warning-100.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.warning-text {
  width: calc(100% - 40px);
  font-size: 10px;
  text-overflow: ellipsis;
}

.br,
.form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-content {
}

#dropdown {
  width: 100%;
  padding: 5px;
  border-radius: 7px;
}

.warning-icon1 {
  width: 25px;
  height: 25px;
  background-image: url("../../assets/icons/icons8-warning-100.png");
  background-size: contain;
  background-repeat: no-repeat;
  margin-right: 10px;
}

.warning-text1 {
  width: calc(100% - 20px);
  font-size: 10px;
  text-overflow: ellipsis;
}

#sub-title {
  font-weight: 600;
  margin-top: 10px;
}

.event {
  width: 100%;
  max-height: 40px;
  padding: 10px;
  box-sizing: border-box;
  border-radius: 7px;
  border: 1px solid black;
}

.desc {
  min-height: 90px;
  max-width: 100%;
  min-width: 100%;
  max-height: 90px;
  resize: none;
  overflow: auto;
}

#sub-heading {
  font-weight: 600;
}

#sub-heading1 {
  font-weight: 600;
  margin-top: 6%;
}

.prev-container {
  width: 100%;
}

#date-selecter {
  width: 100%;
  box-sizing: border-box;
  padding: 5px;
  border-radius: 7px;
  border: 1px solid black;
}

.sc-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 20px;
}

.sc-inner-cont {
  margin-right: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 20px);
}

.sc-heading {
  width: 70%;
  font-size: 14px;
  font-weight: 600;
}

.help-icon {
  width: 20px;
  height: 20px;
  background-image: url("../../assets/icons/icons8-help-100.png");
  background-size: contain;
  background-repeat: no-repeat;
}

#visit {
  padding: 5px;
  border-radius: 7px;
}

#reward {
  width: 100%;
  padding: 5px;
  margin-block: 10px;
  border-radius: 7px;
}

.sc-content2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  /* margin-top: 10px; */
}

.voucher__amount-hint {
  margin-top: 12px;
}

.start-btn {
  width: 88%;
  box-sizing: border-box;
  padding: 10px;
  border-radius: 7px;
  border: none;
  background-color: var(--primary-colour);
  color: white;
  font-weight: 800;
  margin-top: 5%;
  margin-bottom: 5%;
  margin-left: 6%;
}

.start-btn:hover {
  box-shadow: 0 0 10px var(--primary-colour);
}

.start-btn:disabled {
  opacity: 0.5;
}

#drop-area {
  width: 100%;
  min-height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* flex-direction: column; */
}

.outer-div {
  margin-top: 10px;
  display: flex;
  min-height: 100px;
  justify-content: center;
  /* align-items: center; */
  width: 100%;
  border-radius: 7px;
  border: 1px solid black;
  flex-direction: column;
}

.remove-btn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.pr-outer-div {
  margin-top: 10px;
  /* background-color: yellowgreen; */
  width: 100%;
  height: 130px;
  position: relative;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.remove-btn button {
  border: none;
  background-color: transparent;
  color: var(--primary-colour);
}

.remove-btn button:hover {
  font-weight: 900;
}

.pr-bottom {
  width: 100%;
  height: 25px;
  background-color: white;
  position: absolute;
  bottom: 0;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  color: grey;
  padding-inline: 10px;
  box-sizing: border-box;
}

.new {
  width: fit-content;
  padding: 5px;
  background-color: yellow;
  border-radius: 5px;
  font-weight: 800;
  color: black;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: -2%;
  /* transform: rotate(45deg); */
}

.prcontent {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: calc(100% - 25px);
}

.uploaded-img {
  margin-left: 15px;
  width: 80px;
  height: 80px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  /* background-color: blueviolet; */
  border-radius: 5px;
}

.pr-heading {
  font-size: 12px;
}

.pr-sub {
  font-weight: 900;
  font-size: 18px;
  margin-top: 5px;
}

.prtitles {
  width: calc(100% - 100px);
  margin-left: 5px;
  box-sizing: border-box;
}

.pr-form {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-block: 20px; */
  /* flex-direction: column; */
}

.phone-pr {
  /* margin-top: 20px; */
  /* background-color: aqua; */
  width: 100%;
  padding-inline: 20px;
  box-sizing: border-box;
  overflow: auto;
}

/* .phone-pr::-webkit-scrollbar {
    display: none;
  } */

.ph-req,
.ph-subheding {
  margin-top: 15px;
  margin-bottom: 10px;
  font-weight: 900;
  font-size: 12px;
}

.ph-disc,
.req-list li {
  font-size: 12px;
  color: grey;
}

.enddate {
  font-size: 12px;
  font-weight: 700;
  color: grey;
}

.disval {
  font-weight: 700;
  margin-bottom: 5px;
}

.ph-small-heading {
  margin-top: 10px;
  margin-inline: 10px;
  font-size: 12px;
  font-weight: 900;
  color: var(--primary-colour);
}

.uploaded-image {
  width: 100%;
  height: 327px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

.phone-content {
  position: relative;
}

.logo-details {
  display: flex;
  margin-inline: 10px;
  margin-top: 10px;
  justify-content: flex-start;
  align-items: center;
  gap: 10px; /* This adds space between flex items */
}

/* .ph-logo{
    width: 80px;
    height: 80px;
    background-color: blueviolet;
    border-radius: 50%;
    margin-right: 10px;
}
 */
.ph-logo {
  width: 80px;
  height: 80px;
  background-size: cover; /* Ensures the image covers the entire container */
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 50%; /* Makes the container circular */
  overflow: hidden; /* Ensures the image does not spill outside the circular shape */
}

.pr-content-div {
  margin-inline: 10px;
  height: fit-content;
  overflow: visible;
}

.phone-content {
  padding-top: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
  background-color: #edf2fb;
}

.number {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: orange;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
  color: white;
  font-size: 16px;
  font-weight: bold;
}

.date-range-picker {
  display: flex;
  justify-content: center;
}

.date-range-picker .rdp {
  --rdp-accent-color: rgba(248, 124, 86, 0.8);

  margin: 0;
}

@media screen and (max-width: 1300px) {
  .date-range-picker .rdp {
    --rdp-cell-size: 32px;
  }
}

@media screen and (min-width: 1800px) {
  .date-range-picker .rdp {
    --rdp-cell-size: 48px;
  }
}

.create-voucher fieldset {
  margin: 0;
  padding: 0;
  border: 0 none;
}
