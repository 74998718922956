.button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    vertical-align: top;
    min-width: 100px;
    padding: 9px 24px;
    border: 1px solid transparent;
    background-color: var(--primary-colour);
    box-shadow: var(--shadow-over-white-background);
    border-radius: 5px;
    font-family: inherit;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
    text-align: center;
    text-decoration: none;
    white-space: nowrap;
    cursor: pointer;
  user-select: none;
  transition:
    background-color var(--transition-hover),
    border-color var(--transition-hover),
    box-shadow var(--transition-hover),
    color var(--transition-hover);
}

.button__icon {
    vertical-align: top;
    fill: currentColor;
    width: 20px;
    height: 20px;
    margin-top: 2px;
    margin-bottom: 2px;
}


.button:hover {
    background-color: var(--primary-colour-hover);
}


.button--full-width {
    width: 100%;
}

.button--almost-a-square {
    min-width: 0;
    width: 60px;
    padding-left: 0;
    padding-right: 0;
}

.button--secondary {
    background-color: #FFFFFF;
    color: #000000;
}

.button--secondary:hover {
    background-color: var(--primary-colour-hover);
    border-color: transparent;
    color: #FFFFFF;
}


.button--faded {
    background-color: #B7BDC2;
    border-color: transparent;
    color: #000000;
}

.button--faded:hover {
    background-color: #CBD2D7;
    border-color: transparent;
    color: #000000;
}


.button--outline {
    border-color: #000000;
    background-color: #FFFFFF;
    color: #000000;
}

.button--outline:hover {
    border-color: transparent;
    background-color: var(--primary-colour-hover);
  color: #ffffff;
}

.button--paypal {
  border-color: #27346a;
  background-color: #27346a;
  color: #ffffff;
}

.button--paypal:hover {
  border-color: #2790c3;
  background-color: #2790c3;
  color: #ffffff;
}

.button--link-alike {
  background-color: transparent;
  box-shadow: none;
  color: var(--brand);
}

.button--link-alike:hover {
  background-color: transparent;
}
.button:active {
    translate: 0 1px;
    transition: none;
}

.button:disabled,
.button:disabled:hover,
.button:disabled:active,
.button--disabled,
.button--disabled:hover,
.button--disabled:active {
  background-color: var(--grey);
  border-color: transparent;
  box-shadow: none;
  color: #ffffff;
  translate: none;
  cursor: default;
}


.button--create {
    border-radius: 15px;
    padding-top: 23px;
    padding-bottom: 23px;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
}

.button--submit {
    border-radius: 15px;
    padding-top: 13px;
    padding-bottom: 13px;
    font-weight: bold;
    font-size: 24px;
    line-height: 26px;
}

.button--download-qr {
    border-radius: 15px;
    padding: 22px 20px 22px 0;
    justify-content: space-evenly;
    font-size: 20px;
    line-height: 27px;
    text-align: left;
    gap: 0;
}

.button--download-qr .button__icon {
    margin-top: 0;
    margin-bottom: 0;
    width: 54px;
    height: 54px;
}

.button--skip {
    border-radius: 15px;
    font-size: 24px;
    line-height: 30px;
}

.button--skip .button__icon {
    margin-top: 0;
    margin-bottom: 0;
    width: 20px;
    height: 20px;
}

.button--landing {
    border-radius: 12px / 22px;
    font-weight: var(--extra-bold);
    font-size: 17px;

}

.button--landing:hover {
}

.button--smaller {
  min-width: 60px;
  gap: 7px;
  padding: 6px 14px;
  border-radius: 4px;
  font-size: 15px;
  line-height: 22px;
}

@media (900px <= width < 1500px) {
  .button {
    gap: 7px;
    min-width: 75px;
    padding: 9px 18px;
    border-radius: 4px;
    font-size: 15px;
    line-height: 20px;
  }

  .button__icon {
    width: 16px;
    height: 16px;
  }

  .button--create {
    border-radius: 12px;
    padding-top: 18px;
        padding-bottom: 18px;
        font-size: 20px;
        line-height: 24px;
    }

    .button--submit {
        border-radius: 12px;
        padding-top: 10px;
        padding-bottom: 10px;
        font-size: 20px;
        line-height: 24px;
    }


    .button--download-qr {
        border-radius: 12px;
        padding: 18px 16px 18px 0;
        font-size: 18px;
        line-height: 26px;
    }

    .button--download-qr .button__icon {
        width: 44px;
        height: 44px;
    }

    .button--skip {
        border-radius: 12px;
        font-size: 18px;
        line-height: 24px;
    }

    .button--skip .button__icon {
        width: 16px;
        height: 16px;
    }

    .button--landing {
        border-radius: 11px / 20px;
    }


    .button--almost-a-square {
        min-width: 0;
        width: 50px;
    }

}


