.brand-card {
    padding: 12px;
    border-radius: 15px;
    box-shadow: var(--shadow-over-white-background);
}

.brand-card__head {
    display: flex;
    align-items: stretch;
    gap: 15px;
}

.brand-card__preview {
    flex-shrink: 0;
    overflow: hidden;
    align-self: center;
    transition: filter var(--transition-hover);
}

a.brand-card__preview:hover {
    filter: brightness(1.05) contrast(1.05);
}

.brand-card__logo {
    vertical-align: top;
    width: auto;
    height: 60px;
    max-width: 120px;
    object-fit: contain;
    border-radius: 10px;
}

.brand-card__summary {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.brand-card__heading {
    margin-top: 4px;
    margin-bottom: auto;
    font-weight: var(--extra-bold);
    font-size: 24px;
    line-height: 24px;
}

.brand-card__link {
    text-decoration: none;
    color: inherit;
    transition: color var(--transition-hover);
}

.brand-card__link:hover {
    color: var(--brand);
}

.brand-card__addition {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 2px;
    gap: 16px;
}

.brand-card__industry {
    margin-top: 2px;
    font-weight: var(--light);
    font-size: 12px;
    line-height: 14px;
}

.brand-card__vouchers {
    display: flex;
    align-items: center;
    gap: 7px;
    flex-shrink: 0;
    font-weight: bold;
    font-size: 20px;
    line-height: 20px;
}

.brand-card__vouchers-icon {
    margin-top: -1px;
    vertical-align: top;
    width: 23px;
    height: auto;
}

.brand-card__actions {
    margin-top: 23px;
    margin-left: -12px;
    margin-right: -12px;
}

.brand-card__sub-heading {
    margin-top: 13px;
    margin-bottom: 0;
    font-weight: var(--black);
    font-size: 13px;
    line-height: 18px;
}

.brand-card__body {
    margin-top: 4px;
    font-size: 12px;
    line-height: 16px;
    white-space: pre-line;
}


@media (900px <= width < 1500px) {

    .brand-card {
        padding: 10px;
        border-radius: 12px;
    }

    .brand-card__head {
        gap: 12px;
    }

    .brand-card__preview {

    }

    a.brand-card__preview:hover {

    }

    .brand-card__logo {
        height: 46px;
        max-width: 92px;
    }

    .brand-card__summary {

    }

    .brand-card__heading {
        font-size: 18px;
        line-height: 20px;
    }

    .brand-card__addition {
        gap: 12px;
    }

    .brand-card__industry {
        font-size: 11px;
        line-height: 14px;
    }

    .brand-card__vouchers {
        gap: 4px;
        font-size: 16px;
        line-height: 18px;
    }

    .brand-card__vouchers-icon {
        width: 17px;
    }

    .brand-card__actions {

    }

    .brand-card__sub-heading {

    }

    .brand-card__body {
        white-space: pre-line;
    }
}


