@media (740px <= width) {
  /* Firefox */
  .css-scrollbar {
    scrollbar-color: #7d7d7d transparent;
  }

  /* All other browsers */

  .css-scrollbar::-webkit-scrollbar {
    width: 11px;
    cursor: auto;
    background-color: transparent;
  }

  .css-scrollbar::-webkit-scrollbar-thumb {
    background-color: #c2c2c2;
    border-radius: 3px; /* to visually cut corners so it won't overlap borders of textarea */
  }

  .css-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #7d7d7d;
  }

  /* There was a bug that cursor becomes to be text cursor if applied to textarea. This line fixes it. */
  textarea.css-scrollbar {
    cursor: auto;
  }

  /* stylelint-disable */
  .css-scrollbar::-webkit-scrollbar-thumb {
    background-color: #ffffff;
    background-image: linear-gradient(to right, #ffffff, #ffffff),
      linear-gradient(to right, #ffffff, #ffffff),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #fbfbfb 2px,
        #fbfbfb 3px,
        #e0e0e0 3px,
        #e0e0e0 4px,
        #d1d1d1 4px,
        #d1d1d1 5px,
        #c2c2c2 5px,
        #c2c2c2 6px,
        #d1d1d1 6px,
        #d1d1d1 7px,
        #e0e0e0 7px,
        #e0e0e0 8px,
        #fbfbfb 8px,
        #fbfbfb 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #fbfbfb 2px,
        #fbfbfb 3px,
        #e0e0e0 3px,
        #e0e0e0 4px,
        #d1d1d1 4px,
        #d1d1d1 5px,
        #c2c2c2 5px,
        #c2c2c2 6px,
        #d1d1d1 6px,
        #d1d1d1 7px,
        #e0e0e0 7px,
        #e0e0e0 8px,
        #fbfbfb 8px,
        #fbfbfb 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #e0e0e0 2px,
        #e0e0e0 3px,
        #d1d1d1 3px,
        #d1d1d1 4px,
        #c2c2c2 4px,
        #c2c2c2 7px,
        #d1d1d1 7px,
        #d1d1d1 8px,
        #e0e0e0 8px,
        #e0e0e0 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #e0e0e0 2px,
        #e0e0e0 3px,
        #d1d1d1 3px,
        #d1d1d1 4px,
        #c2c2c2 4px,
        #c2c2c2 7px,
        #d1d1d1 7px,
        #d1d1d1 8px,
        #e0e0e0 8px,
        #e0e0e0 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #d1d1d1 2px,
        #d1d1d1 3px,
        #c2c2c2 3px,
        #c2c2c2 8px,
        #d1d1d1 8px,
        #d1d1d1 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #d1d1d1 2px,
        #d1d1d1 3px,
        #c2c2c2 3px,
        #c2c2c2 8px,
        #d1d1d1 8px,
        #d1d1d1 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #c2c2c2 2px,
        #c2c2c2 9px,
        #ffffff 9px,
        #ffffff
      );
    background-size:
      11px 1px,
      11px 1px,
      11px 2px,
      11px 2px,
      11px 3px,
      11px 3px,
      11px 4px,
      11px 4px,
      11px;
    background-position:
      right top,
      right bottom,
      right top,
      right bottom,
      right top,
      right bottom,
      right top,
      right bottom,
      right center;
    background-repeat: no-repeat;
  }

  .css-scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #ffffff;
    background-image: linear-gradient(to right, #ffffff, #ffffff),
      linear-gradient(to right, #ffffff, #ffffff),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #f7f7f7 2px,
        #f7f7f7 3px,
        #bebebe 3px,
        #bebebe 4px,
        #9d9d9d 4px,
        #9d9d9d 5px,
        #7d7d7d 5px,
        #7d7d7d 6px,
        #9d9d9d 6px,
        #9d9d9d 7px,
        #bebebe 7px,
        #bebebe 8px,
        #f7f7f7 8px,
        #f7f7f7 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #f7f7f7 2px,
        #f7f7f7 3px,
        #bebebe 3px,
        #bebebe 4px,
        #9d9d9d 4px,
        #9d9d9d 5px,
        #7d7d7d 5px,
        #7d7d7d 6px,
        #9d9d9d 6px,
        #9d9d9d 7px,
        #bebebe 7px,
        #bebebe 8px,
        #f7f7f7 8px,
        #f7f7f7 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #bebebe 2px,
        #bebebe 3px,
        #9d9d9d 3px,
        #9d9d9d 4px,
        #7d7d7d 4px,
        #7d7d7d 7px,
        #9d9d9d 7px,
        #9d9d9d 8px,
        #bebebe 8px,
        #bebebe 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #bebebe 2px,
        #bebebe 3px,
        #9d9d9d 3px,
        #9d9d9d 4px,
        #7d7d7d 4px,
        #7d7d7d 7px,
        #9d9d9d 7px,
        #9d9d9d 8px,
        #bebebe 8px,
        #bebebe 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #9d9d9d 2px,
        #9d9d9d 3px,
        #7d7d7d 3px,
        #7d7d7d 8px,
        #9d9d9d 8px,
        #9d9d9d 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #9d9d9d 2px,
        #9d9d9d 3px,
        #7d7d7d 3px,
        #7d7d7d 8px,
        #9d9d9d 8px,
        #9d9d9d 9px,
        #ffffff 9px,
        #ffffff
      ),
      linear-gradient(
        to right,
        #ffffff,
        #ffffff 2px,
        #7d7d7d 2px,
        #7d7d7d 9px,
        #ffffff 9px,
        #ffffff
      );
  }
  /* stylelint-enable */
}
