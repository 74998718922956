.explainer {
    position: relative;
    top: -1px;
    display: inline-block;
    vertical-align: middle;
    line-height: 16px;
    padding: 4px; /* make hover area larger */
    margin: 0 4px;
}

.explainer__icon {
    vertical-align: top;
    height: auto;
}
