.choice {
  position: relative;
  display: block;
  width: fit-content;
  min-width: 20px;
  min-height: 34px;
}

.choice__widget {
  opacity: 0;
  position: absolute;
  left: 2px;
  top: 12px;
}

.choice__label {
  position: relative;
  display: flex;
  vertical-align: top;
  padding: 7px 0 7px 30px;
  font-size: 15px;
  line-height: 20px;
  color: #0e132b;
  cursor: pointer;
  user-select: none;
}

.choice__icon {
  position: absolute;
  left: 0;
  top: 8px;
  width: 18px;
  height: 18px;
  border: 2px solid var(--choice-border);
  background-color: #ffffff;
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 2px;
  pointer-events: none; /* Since this icon is positioned higher than label, let's make it possible to click through, so that the user interacts only with the label. For now, this icon won't generate its own cursor, for example. */
  transition:
    background-color var(--transition-hover),
    border-color var(--transition-hover);
}

.choice__widget[type="checkbox"] ~ .choice__icon {
  /* by default it can't be seen because of white background */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48cGF0aCBkPSJNNiAxM0wxIDhMMi40MSA2LjU5TDYgMTAuMTdMOS43OTUgNi4zNzVMMTMuNTkgMi41OEwxNSA0TDYgMTNaIiBmaWxsPSJ3aGl0ZSIvPjwvc3ZnPg==);
}

.choice__widget[type="radio"] ~ .choice__icon {
  /* by default it can't be seen because of white background */
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIHZpZXdCb3g9IjAgMCAxNiAxNiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48Y2lyY2xlIGN4PSI4IiBjeT0iOCIgcj0iMyIgZmlsbD0id2hpdGUiLz48L3N2Zz4=);
  border-radius: 50%;
}

/* Hovered */

.choice__label:hover ~ .choice__icon {
  background-color: #ffffff;
  border-color: var(--choice-border-hover);
}

/* Checked */

.choice__widget:checked ~ .choice__icon {
  transition: none;
  background-color: var(--primary-colour);
  border-color: transparent;
}

/* Checked & Hovered */

.choice__widget:checked ~ .choice__label:hover ~ .choice__icon {
  background-color: var(--primary-colour-hover);
  border-color: var(--primary-colour-hover);
}

.choice__widget[type="radio"]:checked ~ .choice__label {
  cursor: default; /* checked radio button can’t be hovered */
}

.choice__widget[type="radio"]:checked ~ .choice__label:hover ~ .choice__icon {
  background-color: var(
    --primary-colour
  ); /* checked radio button can’t be hovered, so taket the original color back */
  border-color: transparent; /* checked radio button can’t be hovered, so taket the original color back */
}

/* Focused */

.choice__widget:focus-visible ~ .choice__icon {
  outline: auto;
}

/* Disabled */
.choice__widget:disabled ~ .choice__label {
  cursor: default;
  color: var(--disabled-color);
}

.choice__widget:disabled ~ .choice__icon,
.choice__widget:disabled ~ .choice__label:hover ~ .choice__icon {
  background-color: #ffffff;
  border-color: #e5e5e5;
}

.choice__widget:disabled:checked ~ .choice__icon,
.choice__widget:disabled:checked ~ .choice__label:hover ~ .choice__icon {
  background-color: #e5e5e5;
  border-color: transparent;
}

.choice--full-width {
  width: 100%;
}

@media (900px <= width < 1500px) {
  .choice__label {
    font-size: 14px;
  }
}
