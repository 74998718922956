.param-link {
    padding: 6px 14px;
    display: flex;
    align-items: center;
    color: inherit;
    text-decoration: none;
    transition: background-color var(--transition-hover);
}

.param-link:hover {
    background-color: var(--hover);
}
    .param-link__icon {
        flex-shrink: 0;
        margin-top: -1px;
        margin-right: 12px;
        width: 20px;
        height: 20px;
    }

    .param-link__label {
        padding-right: 8px;
        font-size: 12px;
        line-height: 16px;
    }

    .param-link__external {
        flex-shrink: 0;
        width: 16px;
        height: 16px;
    }

    .param-link__dropdown {
        flex-shrink: 0;
        width: 10px;
        height: 6px;
    }

    .param-link__map {
        margin-left: auto;
        width: auto;
        margin-top: -2px;
    margin-bottom: -2px;
    height: 23px;
}


@media (740px <= 900px

<=
width

<
1500
px

)
{

.param-link {
    padding: 4px 10px;
}

.param-link:hover {

}

.param-link:active {

}

.param-link__icon {
    margin-right: 8px;
            width: 16px;
            height: 16px;
        }

        .param-link__label {

        }

        .param-link__external {
            margin-left: 6px;
            width: 14px;
            height: 14px;
        }

        .param-link__dropdown {
            width: 8px;
            height: 5px;
        }

        .param-link__map {
            margin-top: -1px;
            margin-bottom: -1px;
            height: 18px;
        }
}
