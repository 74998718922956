.pattern-picker {
  position: relative;
}

.pattern-picker__handler {
  display: flex;
  align-items: center;
  gap: 18px;
  background-color: transparent;
  padding: 0;
  border: none;
  font-family: inherit;
  font-size: 16px;
  line-height: 20px;
  white-space: nowrap;
  cursor: pointer;
}

.pattern-picker__preview {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  padding: 3px;
}

.pattern-picker__preview .pattern {
  border-radius: 4px;
}

.pattern-picker__preview .pattern__gradient {
  transform: scale(20%);
  left: -200%;
  top: -200%;
  right: -200%;
  bottom: -200%;
}

.pattern-picker__label {
}

.pattern-picker__dropdown {
  position: absolute;
  z-index: 100;
  right: 0;
  width: 330px;
  top: 100%;
  margin-top: 10px;
  background-color: #ffffff;
  background-clip: padding-box;
  border-radius: 8px;
  box-shadow:
    0 6px 16px 0 rgba(0, 0, 0, 0.08),
    0 3px 6px -4px rgba(0, 0, 0, 0.12),
    0 9px 28px 8px rgba(0, 0, 0, 0.05);
  padding: 20px 33px 33px;
  opacity: 0;
  visibility: hidden;
  transition:
    visibility var(--transition-dropdown),
    opacity var(--transition-dropdown);
}

.pattern-picker--expanded .pattern-picker__dropdown {
  opacity: 1;
  visibility: visible;
}

.pattern-picker__sub-heading {
  font-weight: var(--black);
  font-size: 24px;
  line-height: 32px;
}

.pattern-picker__close {
  position: absolute;
  left: 100%;
  bottom: 100%;
  margin-left: -12px;
  margin-bottom: -12px;
}

.pattern-picker__list {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
}

.pattern-picker__item {
  aspect-ratio: 1 / 1;
  padding: 0;
  border: none;
  border-radius: 15px;
  overflow: hidden;
  cursor: pointer;
}

.pattern-picker__item .pattern__gradient {
  transform: scale(50%);
  left: -50%;
  top: -50%;
  right: -50%;
  bottom: -50%;
}

@media (900px <= width < 1500px) {
  .pattern-picker {
  }

  .pattern-picker__handler {
  }

  .pattern-picker__preview {
  }

  .pattern-picker__preview .pattern__gradient {
  }

  .pattern-picker__label {
  }

  .pattern-picker__dropdown {
    width: 260px;
    border-radius: 6px;
    padding: 20px 26px 26px;
  }

  .pattern-picker__sub-heading {
    font-size: 20px;
    line-height: 28px;
  }

  .pattern-picker__list {
    margin-top: 15px;
    gap: 9px;
  }

  .pattern-picker__item {
    border-radius: 12px;
  }
}
