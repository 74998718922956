.geoapify__output {
    margin-top: 14px;
    font-weight: 600;
    font-size: 16px;
    line-height: 20px;
}
    .geoapify__output::before {
        content: "Selected location:";
        display: block;
        margin-bottom: 4px;
        font-weight: normal;
        font-size: 12px;
        line-height: 16px;
        color: var(--dark-grey);
    }
