
.cl-main-content {
    padding-bottom: 24px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: white;
}

.cl-content {
    padding-bottom: 24px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    background-color: white;
}

.cl-subtitle {
    width: '100%';
    margin: 6%;
    margin-left: 7%;
    font-size: 16px;
    font-weight: bold;
}

.cl-preview-card {
    width: 80%;
    height: 16%;
    margin: 6%;
    padding: 4%;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
}

.cl-card-image {
    width: 40%;
    height: 98%;
    align-self: center;
    border-radius: 2px;
    background-color: lightgray;
    /*     background-image: url('../../assets/images/logo2.png'); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.top-content {
    display: flex;
    width: 100%;
    height: 40%;
}

.cl-preview-card2 {
    width: 80%;
    height: 36%;
    margin: 6%;
    padding: 4%;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.cl-card-image2 {
    width: 65%;
    height: 98%;
    border-radius: 2px;
    background-color: lightgray;
    /* align-items: center;
    justify-content: center; */
    /*    background-image: url('../../assets/images/logo2.png'); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
}

.card-title {

    width: 60%;
    height: 100%;
    margin-left: 5%;
    font-weight: bold;
}

.card-subtitle {
    width: 100%;
    height: 100%;
    margin-left: 1%;
    font-weight: lighter;
    color: grey;
    font-size: 10px;
    display: flex;

}


.card-description {
    font-size: 12px;
    color: grey;
}

/* .cl-gmaterial {
    width: 80%;
    height: 10%;
    margin: 6%;
    padding: 4%;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
}

.cl-gmaterial-qr {
    width: 60px;
    height: 60px;
    align-self: center;
    border-radius: 8px;
    background-image: url('"../../assets/icons/QRicon.png');
    background-size:cover;
    background-repeat: no-repeat;
} */

.cl-gmaterial {
    width: 80%;
    height: auto;
    margin: 6%;
    padding: 4%;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    /*   background-color: var(--primary-colour); */
}

.cl-gmaterial-outer-card {
    width: 77%;
    height: auto; /* Adjust height as needed */
    margin: 6%;
    padding: 4%;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
    align-items: center;
    justify-content: center; /* Center content horizontally */
    border: 4px solid var(--primary-colour); /* Thin colored border */
    background-color: transparent; /* Remove the background color */
}

.qr-code-container {
    width: 200px; /* Adjust as needed */
    height: 200px; /* Adjust as needed */
    background-color: white; /* White background for QR code */
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px; /* Optional: to match the style */
}


.cl-gmaterial-qr {
    width: 100%; /* Slightly smaller than the container */
    height: 100%; /* Slightly smaller than the container */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}


.card-title {
    width: 60%;
    height: 100%;
    margin-left: 5%;
    font-weight: bold;
    align-self: center; /* Align text vertically */
    color: black;
}

.cl-gmaterial-poster {
    width: 60px;
    height: 60px;
    align-self: center;
    border-radius: 8px;
    background-image: url('../../assets/icons/QRicon.png');
    background-size: cover;
    background-repeat: no-repeat;
}

.subtitle {
    width: '100%';
    margin: 3%;
    margin-left: 5.5%;
    font-size: 16px;
    font-weight: bold;
}

.bname-input {
    width: 100%;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #5D6164;
    padding-inline: 10px;
}

.descrip-input {
    width: 100%;
    min-height: 20%;
    max-height: 150px;
    border-radius: 10px;
    border: 1px solid #5D6164;
    padding-inline: 2%;
    overflow: hidden;
    resize: none;
    overflow:auto;
}

.company-logo {
    width: 100%;
    height: 10%;
    margin-top: 2%;
    padding: 3%;
    border-radius: 10px;
    border: 1px solid #5D6164;
    display: flex;
    align-items: center;
}

.logo-image {
    width: 60px;
    height: 60px;
    align-self: center;
    border-radius: 8px;
    background-image: url('../../assets/icons/logoicon.png');
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
}

.select-industry {
    width: 100%;
    height: 30px;
    border-radius: 10px;
    border: 1px solid #5D6164;
}

.country {
    width: 27%;
    height: 30px;
    margin-left: 5%;
    border-radius: 5px;
    border: 1px solid #5D6164;
}

.region {
    width: 27%;
    height: 30px;
    margin-left: 4%;
    border-radius: 5px;
    border: 1px solid #5D6164;
}

.city {
    width: 27%;
    height: 30px;
    margin-left: 4%;
    border-radius: 5px;
    border: 1px solid #5D6164;
}

.color-picker {
    width: 100%;
    display: flex;
}

.color-code {
    font-size: 16px;
    margin: 1%;
    margin-left: 3%;
}

.color-preview {
    margin-top: 10px;
    margin-left: 5%;
    background-color: #1677ff;
    /* background-color: transparent !important; */
    width: 90%;
    height: 130px;
    position: relative;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.preview-card-image {
    width: 26%;
    height: 69%;
    margin: 5%;
    align-self: center;
    border-radius: 2px;
    background-color: lightgray;
    /*     background-image: url('../../assets/images/logo2.png'); */
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;

}

.cl-card-image3 {
    width: 24%;
    height: 98%;
    align-self: center;
    border-radius: 2px;
    background-color: lightgray;
    /*     background-image: url('../../assets/images/logo2.png'); */
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

}

.loyalty-btn {
    width: 90%;
    box-sizing: border-box;
    padding: 10px;
    margin-left: 5%;
    border-radius: 7px;
    border: none;
    background-color: var(--primary-colour);
    color: white;
    font-weight: 800;
    margin-top: 5.5%;
}

.loyalty-skip-btn {
    width: 90%;
    box-sizing: border-box;
    padding: 10px;
    margin-left: 5%;
    border-radius: 7px;
    border: none;
    background-color: white;
    color: black;
    font-weight: 800;
    margin-top: 5.5%;
    border: 1px solid black;
}

.loyalty-skip-btn:disabled, .loyalty-btn:disabled {
    opacity: 0.5;
}

.skip-btn {
    width: 90%;
    box-sizing: border-box;
    padding: 10px;
    background-color: white;
    margin-left: 5%;
    border-radius: 7px;
    border: 1px solid var(--primary-colour);
    color: white;
    font-weight: 800;
    margin-top: 5.5%;
}

.text-wrapper-13 {
    position: relative;
    width: 100%;
    /* margin-top: -1px; */
    font-family: "Nunito Sans", Helvetica;
    font-weight: 700;
    color: #000000;
    font-size: 24px;
    text-align: center;
    letter-spacing: 0;
    line-height: 26px;
    white-space: nowrap;
}

.qr-cde-buttom-2 {
    height: 50px;
    background-color: #ffffff;
    border: 1px solid;
    border-color: #000000;
    box-shadow: 0px 4px 10px #00000040;
    position: relative;
    width: 100%;
    border-radius: 15px;
    margin-top: 10px;
}

.skip-btn-div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.skip-text {
    margin-right: 10px;
}

.frame-10 {
    display: inline-flex;
    align-items: center;
    position: relative;
    top: 12px;
    left: 126px;
}

.frame-11 {
    position: relative;
    width: 15px;
    height: 24px;
}


.card-previewtitle {
    width: 143px;
    height: 35%;
    margin: 3%;
    margin-left: 0;
    font-size: 14px;
    padding: 1%;
    font-weight: bold;
    /* color: white; */
    /* background-color: #0f56ba; */
}

.cl-preview-card3 {
    width: 80%;
    height: 10%;
    /* margin-top: 0%; */
    margin-left: 6%;
    margin-bottom: 6%;
    padding: 4%;
    border-radius: 0 0 15px 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
    display: flex;
}


.bg-preview {
    width: 88%;
    height: 9%;
    margin-left: 6%;
    margin-top: 2%;
    background-color: #1677ff;
    background-image: url('../../assets/images/group_127.png');
    background-size: contain;
}

.upload-instructions {
    text-align: center; /* Center aligns the content */
}

.size-restriction {
    font-size: smaller; /* Makes the font smaller */
    text-align: center; /* Center aligns the text */
    margin-top: 5px; /* Adds some space between the lines */
}
