.info-panel {
    margin-top: calc(var(--spacing) / 2);
    display: flex;
    align-items: center;
    gap: 20px;
    padding: 18px 24px;
  background-color: rgb(183 189 194 / 22%);
  border-radius: 5px;
  color: #000000;
}

.info-panel__icon {
  flex-shrink: 0;
  width: 32px;
  height: 32px;
}

.info-panel__label {
  font-size: 12px;
  line-height: 16px;
}

@media (900px <= width < 1500px) {
  .info-panel {
    gap: 14px;
    padding: 12px 16px;
    border-radius: 4px;
  }

  .info-panel__icon {
    width: 24px;
    height: 24px;
  }

  .info-panel__label {
    font-size: 11px;
    line-height: 15px;
  }
}

@media (width < 900px) {
  .info-panel {
    gap: 14px;
    padding: 9px 18px;
  }

  .info-panel__icon {
    width: 20px;
    height: 20px;
  }

  .info-panel__label {
  }
}
