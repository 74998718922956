.react-cookienotice-root {
  padding: 20px;
  z-index: 999;
  font-family: inherit;
}

.react-cookienotice-root > .react-cookienotice-body {
  padding: 0;
}

/* Window heading and main text */
.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-title {
  font-weight: bold;
  font-size: 22px;
  line-height: 26px;
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-description {
  font-size: 15px;
  line-height: 22px;
}

/* Checkbox */
.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-checkbox {
  position: relative;
  margin-top: 8px;
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-checkbox
  > input[type="checkbox"] {
  position: absolute; /* Checkbox in the left column takes some place. Make him position absolute to release that space */
  left: 0;
  top: 0;
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-checkbox
  > label {
  background-color: #b0b4ba;
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-checkbox
  > input:checked
  + label {
  background-color: var(--primary-colour);
}

/* Columns layout */
.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-info {
  margin-left: 16px;
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-checkbox {
  flex-shrink: 0;
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-info {
  flex-grow: 1;
}

/* collapse button and dropdown text */
.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-info
  > .react-cookienotice-service-info-title {
  padding: 10px 14px;
  background-color: #eff0f2;
  border-radius: 5px;
  font-family: inherit;
  font-size: 15px;
  line-height: 20px;
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-info
  > .react-cookienotice-service-info-title
  > .react-cookienotice-service-info-title-always-active {
  margin-left: 8px;
  padding: 1px 8px;
  background-color: var(--primary-colour);
  border-radius: 20px;
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-info
  > .react-cookienotice-service-info-title:after {
  width: 16px;
  height: 16px;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTYiIGhlaWdodD0iMTYiIGZpbGw9Im5vbmUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+ICAgIDxwYXRoIGZpbGw9IiMwMDAiIGQ9Ik0zLjI5MyA1LjYwNGExIDEgMCAwIDEgMS40MTQgMEw4IDguODk3bDMuMjkzLTMuMjkzYTEgMSAwIDEgMSAxLjQxNCAxLjQxNWwtNCA0YTEgMSAwIDAgMS0xLjQxNCAwbC00LTRhMSAxIDAgMCAxIDAtMS40MTV6Ii8+PC9zdmc+);
  background-size: 16px 16px;
  background-repeat: no-repeat;
  background-position: center center;
  text-indent: 200%;
  white-space: nowrap;
  overflow: hidden;
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-info
  > .react-cookienotice-service-info-title.open:after {
  transform: scaleY(-1);
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-info
  > .react-cookienotice-service-info-content {
  padding: 0 0 0 14px;
}

.react-cookienotice-root
  > .react-cookienotice-body
  > .react-cookienotice-services
  > .react-cookienotice-service
  > .react-cookienotice-service-info
  > .react-cookienotice-service-info-content
  > .react-cookienotice-service-info-content-description {
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 14px;
  line-height: 20px;
}

/* Buttons */
.react-cookienotice-buttons {
  display: flex;
  flex-direction: row-reverse;
  gap: 10px;
  margin-top: 10px;
}

.react-cookienotice-root
  > .react-cookienotice-buttons
  > .react-cookienotice-button {
  padding: 9px 24px;
  border: 1px solid transparent;
  background-color: #ffffff;
  box-shadow: var(--shadow-over-white-background);
  border-radius: 5px !important;
  font-family: inherit;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  user-select: none;
  transition:
    background-color var(--transition-hover),
    border-color var(--transition-hover),
    box-shadow var(--transition-hover),
    color var(--transition-hover);
}

/* Accept button */
.react-cookienotice-root
  > .react-cookienotice-buttons
  > .react-cookienotice-button:last-child {
  background-color: var(--primary-colour);
  color: #ffffff;
}

.react-cookienotice-root
  > .react-cookienotice-buttons
  > .react-cookienotice-button:last-child:hover {
  background-color: var(--primary-colour-hover);
}
