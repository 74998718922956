.place {
  display: flex;
  flex-direction: column;
  height: 230px;
  border-radius: var(--card-radius);
  box-shadow: var(--shadow-over-white-background);
  color: #ffffff;
}

.place__header {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  padding: 12px;
}

.place__leaf {
  flex-shrink: 0;
  display: inline-block;
  vertical-align: top;
  border-radius: 8px;
  overflow: hidden;
}

.place__logo {
  vertical-align: top;
  width: auto;
  height: 40px;
}

.place__summary {
  margin-top: 2px;
  flex-grow: 1;
}

.place__heading {
  margin-top: 0;
  margin-bottom: 0;
  font-weight: var(--extra-bold);
  font-size: 18px;
  line-height: 20px;
}

.place__location {
}

.place__location .location {
  color: inherit;
}

.place__data {
  flex-shrink: 0;
  text-align: right;
  margin-right: 4px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.place__voucher {
  vertical-align: top;
  fill: currentColor;
  width: 22px;
  height: auto;
}

.place__counter {
  font-weight: bold;
  font-size: 20px;
  line-height: 24px;
}

.place__footer {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 0 2px 12px;
}

.place__category {
  font-weight: var(--light);
  font-size: 14px;
  line-height: 20px;
}

.place__more {
  background-color: transparent;
  line-height: 16px;
  padding: 12px;
  border: none;
  color: inherit;
  cursor: pointer;
}

.place__chevron {
  vertical-align: top;
  width: 20px;
  height: 20px;
  fill: currentColor;
  transition: scale 400ms var(--ease-out-quint);
}

.place__more:hover .place__chevron {
  scale: 1.25;
}

@media (900px <= width < 1500px) {
  .place {
    height: 160px;
  }

  .place__header {
    gap: 8px;
    padding: 9px;
  }

  .place__leaf {
    border-radius: 6px;
  }

  .place__logo {
    height: 34px;
  }

  .place__summary {
  }

  .place__heading {
    font-size: 16px;
    line-height: 18px;
  }

  .place__location {
  }

  .place__location .location {
    /*color: inherit;*/
  }

  .place__data {
    margin-top: 2px;
  }

  .place__voucher {
    width: 18px;
  }

  .place__counter {
    margin-top: 1px;
    font-size: 16px;
    line-height: 20px;
  }

  .place__footer {
    padding: 9px 0 2px 9px;
  }

  .place__category {
    font-size: 13px;
    line-height: 18px;
  }

  .place__more {
    padding: 9px;
  }

  .place__chevron {
    width: 16px;
    height: 16px;
  }
}
