.form {
  border: 0 none;
  padding: 0;
  margin: 0;
}

.field {
  padding: 2px;
}

.field__label {
  font-weight: bold;
  margin-bottom: 2px;
}
.field__input {
  padding: 2px 0;
}
.field__input input {
  width: 100%;
  border-radius: 6px;
}
.field__error {
  color: #b43e3e;
  margin-top: 2px;
  font-size: small;
}
