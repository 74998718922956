.mp-content-card {
  padding: 15px;
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.selected-card {
  margin-bottom: 20px;
  width: calc(250px - 10px);
  height: calc(40% - 10px);
  border: 5px solid #ff7b0f;
  background-color: white;
  margin-right: 2%;
  border-radius: 10px;
  box-shadow: 0 0 7px #ff7b0f;
  display: flex;
  justify-content: center;
  /* align-items: center; */
  flex-direction: column;
}

.loc-name {
  width: 80px;
}

.mv-content {
  width: 100%;
  height: 80px;
  /* background-color: #FF7B0F; */
  margin-top: 10px;
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  box-sizing: border-box;
}

.mv-content-1 {
  width: 100%;
  height: 45%;
  /* background-color: #FF7B0F; */
  margin-top: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.mv-div {
  height: 80%;
  width: 1px;
  background-color: gray;
}

.mv-mi,
.mv-mu {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.mv-edit {
  width: 48%;
  height: 100%;
  display: flex;
  /* padding: 5px; */
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: grey;
}

.mv-btn-vc-toggle {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  background-color: #ff7b0f;
}

.mv-btn-vc-edit {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
}

.mv-vc-toggle {
  width: 48%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 5px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
  background-color: #ff7b0f;
}
