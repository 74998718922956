.vname-main {
    width: 100%;
    height: 100%;
  position: relative;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.3);
  backdrop-filter: blur(7px);

  display: flex;
  justify-content: center;
  align-items: center;
}

.vname-inner-div {
  width: 384px;
  padding: 62px 42px;
  min-height: 440px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  border-radius: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.5);
  position: relative;
}

.c-icon {
  top: 15px;
  right: 15px;

  position: absolute;
    width: 25px;
    height: 25px;
  
  background-image: url("../../assets/icons/close-60.png");
    background-size: contain;
    background-repeat: no-repeat;
  }
/* ---- */

.vname-content{
    /* background-color: blueviolet; */
    margin-top: 20px;
    width: 85%;
    height: 80%;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.vname-h1{
    font-weight: 700;
    font-size: 20px;
}

.vname-h2{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-top: 5px;
}

.vname-h3{
    font-size: 12px;
    font-weight: 700;
    color: black;
    display: flex;
}

.vname-h4{
    font-size: 12px;
    font-weight: 500;
    color: black;
    display: flex;
}

.vname-must{
    margin-top: 15px;
    width: 85%;
}

.vname-cir{
    margin-right: 10px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: lightgray;
}

.vname-h3 div{
    margin-left: 5px;
    font-weight: 800;
    color: #FF780F;
}

.input-div{
    margin-top: 30px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    padding-block: 20px;
    border-radius: 15px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.ver_code-input{
    width: 80%;
    height: 40px;
    border-radius: 10px;
    border: 1px solid gray;
    padding-inline: 10px;
}

.submit-btn{
    margin-top: 30px;
    width: 90%;
    height: 45px;
    border-radius: 10px;
    background-color: #FF780F;
    border: none;
    border-radius: 50px;
    color: white;
    font-weight: bold;
    font-size: 18px;
}